import { useEffect, useMemo, useRef, useState } from "react";

import { IAddress, IMenuItem } from "@cw/models/shared";
import { useFormContext } from "./Form.Context";
import { camelCaseToUserText } from "@cw/utils";
import { FormAutoComplete } from "./FormAutoComplete";
import { GoogleMapsLibraryLoader } from "../GoogleMapsLibraryLoader";

interface IFormAddressLookupProps<T> {
  name: keyof T;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}
export function FormAddressLookup<T>(props: IFormAddressLookupProps<T>) {
  
  const {
    formData,
    handleFormChange
  } = useFormContext<T>();

  const [googleInitialized, setGoogleInitialized] = useState(false);
  const googleAutocompleteApi = useRef<google.maps.places.AutocompleteService>();
  const googlePlacesApi = useRef<google.maps.places.PlacesService>();
  const searchDebounceTimeout = useRef<any>();

  const tempSearchKey = useMemo(() => {
    return `${String(props.name)}_search`;
  }, [props.name]);

  const [isSearching, setIsSearching] = useState(false);
  const [addressOptions, setAddressOptions] = useState<IMenuItem[]>([]);
  const lastSelectedPlaceId = useRef<string>('');

  const handleSearch = (input: string) => {
    if (searchDebounceTimeout.current) {
      clearTimeout(searchDebounceTimeout.current);
      searchDebounceTimeout.current = null;
    }

    if (!input || input.length < 3) {
      return;
    }

    searchDebounceTimeout.current = setTimeout(() => {
      setIsSearching(true);
      googleAutocompleteApi.current?.getPlacePredictions({
        input: input,
        componentRestrictions: {
          country: ['nz']
        },
        types: ['address']
      })?.then((response) => {
        setAddressOptions(response.predictions.map(x => ({
          text: x.description,
          value: x.place_id,
        })));
      }).finally(() => {
        setIsSearching(false);
      });
    }, 200);
  }

  const extractStreet = (place: google.maps.places.PlaceResult): string => {
    if (!place.address_components || place.address_components.length < 1) {
      return '';
    }

    const streetNumberComponent = place.address_components.find(x => x.types.indexOf('street_number') > -1);
    const streetNameComponent = place.address_components.find(x => x.types.indexOf('route') > -1);

    const streetNumber = streetNumberComponent ? `${streetNumberComponent.long_name} ` : '';
    const streetName = streetNameComponent ? streetNameComponent.long_name : '';

    const street = `${streetNumber}${streetName}`;
    return street.trim();
  };

  const extractKeyInfo = (place: google.maps.places.PlaceResult, key: string, fuzzy?: boolean, shortName?: boolean): string => {
    if (!place.address_components || place.address_components.length < 1) {
      return '';
    }

    let result = '';
    for (const component of place.address_components) {
      let hasKey = false;
      for (const type of component.types) {
        if (fuzzy && type.indexOf(key) > -1) {
          hasKey = true;
          break;
        } else if (!fuzzy && type === key) {
          hasKey = true;
          break;
        }
      }
      if (hasKey) {
        result = shortName ? component.short_name : component.long_name;
        break;
      }
    }

    return result.trim();
  }

  useEffect(() => {
    if (formData[props.name]) {
      const { fullAddress, addressId } = (formData[props.name] as IAddress);
      const mockedOption: IMenuItem = {
        value: addressId,
        text: fullAddress,
      };

      const currentTempSearchValue = ((formData as any)[tempSearchKey as any] ?? null) as IMenuItem | null;
      if (!currentTempSearchValue || currentTempSearchValue.value !== mockedOption.value) {
        setAddressOptions([mockedOption]);
        handleFormChange(tempSearchKey as any, mockedOption);
      }

      lastSelectedPlaceId.current = mockedOption.value!;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    const selectedItem = (formData as any)[tempSearchKey] as IMenuItem;
    if (!selectedItem || selectedItem.value === lastSelectedPlaceId.current) {
      return;
    } else {
      lastSelectedPlaceId.current = selectedItem.value!;
      googlePlacesApi.current?.getDetails({
        placeId: selectedItem.value!
      }, (response) => {
        const selectedAddressOption = addressOptions.find(x => x.value === selectedItem.value);
        if (!selectedAddressOption) {
          return;
        }

        const location = response?.geometry?.location;
        
        const address: IAddress = {
          fullAddress: addressOptions.find(x => x.value === selectedItem.value)!.text ?? '',
          street: extractStreet(response!),
          suburb: extractKeyInfo(response!, 'sublocality', true),
          city: extractKeyInfo(response!, 'locality'),
          region: extractKeyInfo(response!, 'administrative_area_level_1'),
          postalCode: extractKeyInfo(response!, 'postal_code'),
          country: extractKeyInfo(response!, 'country'),
          countryCode: extractKeyInfo(response!, 'country', false, true),
          addressId: response?.place_id!,
          location: !location ? null : {
            latitude: location.lat(),
            longitude: location.lng()
          }
        }
        handleFormChange(props.name, address);

        const mockedOption: IMenuItem = {
          value: address.addressId,
          text: address.fullAddress,
        };
        handleFormChange(tempSearchKey as any, mockedOption);
        
        setAddressOptions([selectedAddressOption!]);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
      if (!googleInitialized) {
          return;
      }

      if (!googleAutocompleteApi.current) {
          googleAutocompleteApi.current = new google.maps.places.AutocompleteService();
      }

      if (!googlePlacesApi.current) {
          googlePlacesApi.current = new google.maps.places.PlacesService(document.createElement('div'));
      }      
  }, [googleInitialized]);

  return (
    <GoogleMapsLibraryLoader statusChanged={(status) => setGoogleInitialized(status === 'SUCCESS')}>
      <FormAutoComplete<T>
        options={addressOptions}
        onSearchTextChanged={handleSearch}
        isLoading={isSearching}
        {...props}
        name={tempSearchKey as any}
        label={props.label ?? camelCaseToUserText(String(props.name))}
      />
    </GoogleMapsLibraryLoader>
  );
}
